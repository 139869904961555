import * as React from "react";
import { Badge, TextField, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card } from "@mui/material";
import { REQUESTS } from "../../services/types";
import { css as customCss } from '../../content/Style'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import GroupIcon from "@mui/icons-material/GroupOutlined";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];


export default function Home() {
  let dispatch = useDispatch();
  let dashboardState = useSelector((state) => state.dashboard?.data);
  let authState = useSelector((state) => state.auth.data)
  const [chart, setChart] = React.useState({})


  React.useEffect(() => {
      dispatch({ type: REQUESTS.DASHBOARD.GET.ACTION, payload: 0 });
      console.log("Dashbord state", dashboardState,  (!dashboardState), (!dashboardState?.results), dashboardState?.results?.length==0)
  }, [])

  
  React.useEffect(() => {
    console.log("Dashbord state", dashboardState,  (!dashboardState), (!dashboardState?.results), dashboardState?.results?.length==0)
}, [dashboardState])

  const getChart = (model) => {
    dispatch({
      type: REQUESTS.CHART.GET.ACTION, payload: `chart/0/${model}/`, callback: (data) => {
        setChart(data.results)

      }
    });
  }

  return (
    <>
      <Typography component="h1" sx={{ mb: 2 }}>Hi, {authState.first_name}</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {dashboardState?.results?.map((data, i) => {
          let cs = { ...customCss.Round }
          cs.color = customCss.Colors[parseInt(i % customCss.Colors.length)]?.color
          cs.backgroundColor = customCss.Colors[parseInt(i % customCss.Colors.length)]?.bg
          return <Grid item xs={6} md={3}>
            <Card display={'flex'} onClick={() => { getChart(data.name) }} alignContent={'center'} alignItems={'center'} sx={{ ...customCss.Card.body, p: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Badge sx={cs}>
                  <GroupIcon />
                </Badge>
                <Typography sx={{ fontSize: 12 }}>{data?.name}</Typography>
              </div>
              <div style={{ textAlign: 'right' }}><Typography component={'h1'}
                sx={{ fontSize: 14, background: customCss.Colors[1].bg, display: 'inline-block', p: .2, px: 3, borderRadius: 100 }}>{data.count}</Typography></div>
              <div></div>
            </Card>
          </Grid>
        })}
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          {dashboardState?.results?.length > 0 && <Card sx={{ ...customCss.Card.body, p: 1 }}>

            <BarChart
              series={[
                { data: dashboardState?.results?.map((data, i) => data.count) || [0], label: 'count', id: 'pvId' },
              ]}
              xAxis={[{ data:dashboardState?.results?.map((data, i) => data.name) || [""], scaleType: 'band' }]}
              height={250}
            />
          </Card>}
        </Grid>
        <Grid item xs={12} md={6}>
          {chart.year?.length > 0 &&
            <Card sx={{ ...customCss.Card.body, p: 1 }}>
              {chart?.title}
              <BarChart
                series={[
                  { data: chart.year.map((y) => y.dcount), label: 'Count', id: 'pvId' },
                  // { data: uData, label: 'uv', id: 'uvId' },
                ]}
                xAxis={[{ data: chart.year.map((y) => "Year " + y.label), scaleType: 'band' }]}
                height={250}
              />
            </Card>}
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          {chart.month?.length > 0 &&
            <Card sx={{ ...customCss.Card.body, p: 1 }}>
              {chart?.title}
              <BarChart
                series={[
                  { data: chart.month.map((y) => y.dcount), label: 'Count', id: 'pvId' },
                  // { data: uData, label: 'uv', id: 'uvId' },
                ]}
                xAxis={[{ data: chart.month.map((y) => "Month " + y.label), scaleType: 'band' }]}
                height={250}
              />
            </Card>}
        </Grid>
        <Grid item xs={12} md={6}>
          {chart.day?.length > 0 &&
            <Card sx={{ ...customCss.Card.body, p: 1 }}>
              {chart?.title}
              <BarChart
                series={[
                  { data: chart.day.map((y) => y.dcount), label: 'Count', id: 'pvId' },
                  // { data: uData, label: 'uv', id: 'uvId' },
                ]}
                xAxis={[{ data: chart.day.map((y) => "Day " + y.label), scaleType: 'band' }]}
                height={250}
              />
            </Card>}
        </Grid>
      </Grid>
    </>
  );
}
