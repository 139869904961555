import * as React from "react";
import { Input, ViewSave, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, Stack, capitalize } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Details from "./Details";
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function Branch(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState({});
    let branchState = useSelector((state) => state.branch?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.BRANCH.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        if (data?.image && data?.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.BRANCH.PATCH.ACTION : REQUESTS.BRANCH.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.BRANCH.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.BRANCH.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.BRANCH.GET.ACTION, payload: `branch/0/?filter_by=${query}` })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={branchState?.results}
                    onSubmit={onSubmit}
                    onClickFilter={onClickFilter}
                    // showHeader
                    headerList={[{ key: 'branch_name', label: 'Branch Name', type: 'string' },
                    { key: 'branch_code', label: 'Branch Code', type: 'string' }]}
                    onDelete={onDelete}
                    title={"Branch"}
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'project_name') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key2={"company_details.company_name"} key3={"description"} />
                    }}
                >
                    <p> </p>
                    <Input maxLength={100} label={"Branch Name"} name={"branch_name"} required={true} />
                    <Input maxLength={500} label={"Branch COde "} name={"branch_code"} required={false} />
                    {/* <Input maxLength={30} label={"Latitude"} name={"latitude"} required={false} />
                    <Input maxLength={15} label={"Longitude"} name={"longitude"} required={false} /> */}
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}