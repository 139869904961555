import * as React from "react";
import { Input, ViewSave, makeQuery, makeSearchQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


export default function BranchReport(props) {
    const [more, setMore] = React.useState({});
    let packageState = useSelector((state) => state.branchreport?.data)
    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch({ type: REQUESTS.BRANCHREPORT.GET.ACTION, payload: 0 })
        console.log('report',packageState)
    }, [])

    const onSubmit = (e, data) => {
        let action = data.id ? REQUESTS.BRANCHREPORT.PATCH.ACTION : REQUESTS.BRANCHREPORT.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.BRANCHREPORT.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.BRANCHREPORT.GET.ACTION, payload: 0 })
            }
        })
    }
    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.BRANCHREPORT.GET.ACTION, payload: `package/0/?filter_by=${query}`})
    }
    const onClickSearch = (value) => {
        let query = makeSearchQuery(['description', 'title','days'], value)
        dispatch({ type: REQUESTS.BRANCHREPORT.GET.ACTION, payload: `package/0/?search_by=${query}`})
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <Grid container justifyContent="center" alignItems="center" spacing={6}>
                    <Grid item>
                        <Box sx={{ ...customCss.List.Frame, p: 5, m: 5, width: 240, height: 100, borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', cursor:'pointer' }}>
                            <Typography variant="h6" sx={{ mb: 5,textAlign:'center',paddingTop:'40px'}}>TOTAL COUNT {JSON.stringify(packageState?.grand_totals?.total)}</Typography><br></br>
                            {/* <h2>{JSON.stringify(packageState?.grand_totals?.total)}</h2> */}
                            
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ...customCss.List.Frame, p: 5, m: 5, width: 190, height: 95, borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', cursor:'pointer'}}>
                            <Typography variant="h6" sx={{ mb: 5 ,textAlign:'center',paddingTop:'40px'}}>UPDATED {JSON.stringify(packageState?.grand_totals?.updated)}</Typography><br></br>
                            {/* <h2>{JSON.stringify(packageState?.grand_totals?.updated)}</h2> */}
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ...customCss.List.Frame, p: 5, m: 5, width: 200, height: 95, borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', cursor:'pointer' }}>
                            <Typography variant="h6" sx={{ mb: 5,textAlign:'center',paddingTop:'40px' }}>PENDING {JSON.stringify(packageState?.grand_totals?.not_updated)}</Typography><br></br><br></br>
                            {/* <h2 style={{width:'10px'}}>{JSON.stringify(packageState?.grand_totals?.not_updated)}</h2> */}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item md={6} >
                <ViewSave dataList={packageState?.results}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onClickFilter={onClickFilter}
                    onClickSearch={onClickSearch}
                    //renderSearch={() => <></>}
                    height={'90vh'}
                    title={"Branch Wise Report"}
                    // moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    row={<TableRow></TableRow>}
                    col={<TableCell></TableCell>}
                    head={<th style={{ width: 100 }}></th>}
                    cover={<Table></Table>}
                    body={<TableBody></TableBody>}
                    showColumns={['title']}
                     // customIcon={<CheckCircleIcon />}
                    onMore={(data) => { setMore(data) }}
                    showDwnload={true}
                    hideEditBtn
                    hideDelBtn
                    showHeader
                    noActionCol
                    headerList={[{ key: 'index', style: { width: 40 }, label: 'SL.No' },
                    // {key:'branch_code', label:'Branch Code',type:'number'},
                     {key:'branch_name', label:'Branch Name', type:'string'},
                     {key:'total', label:'Total Count', type:'string'},
                     {key:'updated',label:"Updated Status",type:'string'},
                     {key:'not_updated',label:" Not Updated",type:'string'},
                     
                        
                    ]}
                >
                </ViewSave>
                </Grid>
            </Grid>
        </Grid>
    </>
}