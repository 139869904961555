import React, { useRef } from "react";
import ZFiles from "../../libs/ZFiles";
import {
  Box, Button, Divider, Grid
} from "@mui/material";
function FileManager() {

  const onChangeBatch = (batch, callback) => {
    callback(batch)
  }

  const onResults = (batch) => {
    console.log("resuls", batch.length)
  }

  return (
    <Grid container>
      <Grid md={12} item>
        <Box sx={{ m: 1, p: 2, background: 'white', borderRadius: 4 }}>
          <ZFiles title={"Loan"}
            headerList={[
              { key: "uniqueId", label: "Loan Unique Id" },
              { key: "uniquenumber", label: "Loan Unique Number" }
            ]}
            onChangeBatch={onChangeBatch}
            onResults={onResults}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default FileManager;