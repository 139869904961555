import CropImg from "./imgcrop/CropImg";
import VS from "./ViewSave";
import P from "./Palette";
import AC from "./inputs/XAutoComplate";
import XF from "./inputs/XForm";
import XL from "./inputs/XLocation";
import XG from "./inputs/XGeoMove";
import ZCB from "./ZButton";
import Inp from "./inputs/Input"
import InpImg from "./inputs/ImgInput"

export const CImg = CropImg;
export const ViewSave = VS;
export const Palette = P;
export const XAutoComplete = AC;
export const XForm = XF;
export const XLocation = XL;
export const XGeoMove = XG;
export const ZButton = ZCB;
export const Input = Inp;
export const ImgInput = InpImg;
export const K = (value, label) => value ? value : label
export const cleanUp = (obj) => {
    let result = obj
    if (typeof obj === 'object') {
        Object.keys(obj).map((key) => {
            if (obj[key] && (obj[key] === " " || obj[key] === "" || obj[key] === undefined)) {
                delete obj[key]
            }
        })
    }
    return obj

}
export const checkIsImageURL = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}
export const nextedKey = (obj, key) => {
    if (key in obj) {
        return obj[key];
    }
    const keys = key.split(".");
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
        value = value[keys[i]];
        if (value === undefined) {
            break;
        }
    }
    return value;
}
export const makeQuery = (name, operator, value) => {
    let query = ""
    console.log(name, operator)
    console.log(["1", "0"].includes(operator))
    if (operator == "Contains") {
        query = {}
        query[`${name}__icontains`] = value
        query = JSON.stringify(query)
    } else if (operator == "Starts With") {
        query = {}
        query[`${name}__istartswith`] = value
        query = JSON.stringify(query)
    } else if (operator == "Ends With") {
        query = {}
        query[`${name}__iendswith`] = value
        query = JSON.stringify(query)
    } else if (operator == "Equals") {
        query = `${name} =  '${value}'`
    } else if (operator == "Is Empty") {
        query = `${name} = '' OR ${name} = NULL`
    } else if (operator == "Is Not Empty") {
        query = `${name} != '' OR ${name} != NULL`
    } else if (["1", "0", 1, 0].includes(operator)) {
        query = `${name} = ${operator}`
    } else {
        query = `${name} ${operator} ${value}`
    }
    return query
}

export const makeSearchQuery = (columns, value) => {
    console.log(columns, value)
    let query = {}
    columns.map((name) => {
        query[`${name}__icontains`] = value
    })
    return JSON.stringify(query)
}