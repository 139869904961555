import { Chip, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import XMenu from "../components/basic/XMenu";
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS, LOGOUT } from "../services/types";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import React from "react";
const ZToolbar = ({ title, customCss, handleDrawerOpen, handleDrawerClose, open }, prop) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let userState = useSelector((state) => state.auth.data)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  React.useEffect(() => { }, [open]);

  return (
    <div {...prop}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ px: 1, py: 0.8 }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{
            marginLeft: 5,
            p: 0,
            // ...(open && { display: "none" }),
          }}
        >
          {open ? <span onClick={handleDrawerClose}><NavigateBeforeRoundedIcon /></span> : <MenuIcon onClick={handleDrawerOpen} />}
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <Typography sxvariant="h6" noWrap component="div">
            {!open && title}
          </Typography>
        </Box>
        {userState?.first_name && <Box sx={{ flexGrow: 0, marginRight: 5 }}>
          {/* <Chip label={"Notifications"} size="small" sx={{ mx: 1, ...customCss.AppBar.Chip }} />
          <Chip label={"Messages"} size="small" sx={{ mx: 1, ...customCss.AppBar.Chip }} /> */}
          <Chip label={userState?.first_name + " " + userState?.last_name} size="small" sx={{ mx: 1, ...customCss.AppBar.Chip }} />
          <XMenu
            list={["Profile", "Change Password", "Logout"]}
            onClick={(e, action) => {
              if (action == "Logout") {
                dispatch({
                  type: LOGOUT,
                  payload: {}
                });
                navigate("/")
              }
              if (action == "Change Password") {
                navigate("/changepassword", {
                  state: { data: userState }
                })
              }
            }}
          />
        </Box>}
      </Stack>
    </div>
  );
};
export default ZToolbar;
