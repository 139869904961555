import React, { useEffect } from "react";
import { Offline, Online } from "react-detect-offline";
import "./App.css";
import Layout from "./layout/Layout";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import AnonyLayout from "./layout/AnonyLayout";
import NoInternet from './pages/offline/NoInternet'

function App() {
  let auth = useSelector((state) => state.auth);
  const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
  const authentic = () => {
    if (auth && auth.data && auth.data.token) {
      axios.defaults.headers.common["Authorization"] =
        "Token " + auth.data.token;
    }
  };
  React.useEffect(() => {
    authentic();
  }, []);
  React.useEffect(() => {
    authentic();
  }, [auth]);
  return (
    <>
      {/* <Offline>
        <NoInternet />
        {auth?.data?.token ? <Layout isLogged={auth?.data?.token} name={PROJECT_NAME} ></Layout> : <AnonyLayout />}
      </Offline> */}
      {/* <Online> */}
        {auth?.data?.token ? <Layout isLogged={auth?.data?.token} name={PROJECT_NAME} ></Layout> : <AnonyLayout />}
      {/* </Online> */}
    </>
  );
}
export default App;
