import * as React from "react";
import { Input, ViewSave, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, Stack, capitalize } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Details from "./Details";
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function Center(props) {
    const [more, setMore] = React.useState({});
    let centerState = useSelector((state) => state.center?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.CENTER.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.CENTER.PATCH.ACTION : REQUESTS.PROJECT.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.CENTER.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.CENTER.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.CENTER.GET.ACTION, payload: `center/0/?filter_by=${query}` })
    }
    const onChangeBatch = (uniqueColumn, batch, callback) => {
        //call api
        let data = { model: "center", list: batch, unique_column: uniqueColumn }
        console.log("BATCH", batch)
        dispatch({
            type: REQUESTS.FILEUPLOAD.POST.ACTION, payload: data, callback: (data) => {
                callback(batch)
            }
        })

    }
    const onUploadResults = (results) => {
        console.log("UPLOADED", results.length)
        console.log("UPLOADED DATA", results)
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave
                    showUpload
                    dataList={centerState?.results}
                    onSubmit={onSubmit}
                    onClickFilter={onClickFilter}
                    headerList={[
                        { key: 'center_name', label: 'Center Name', type: 'string' },
                        { key: 'center_code', label: 'Center Code', type: 'string' },
                        { key: 'latitude', label: 'Latitude', type: 'string' },
                        { key: 'longitude', label: 'Longitude', type: 'string' },
                        { key: 'is_update', label: 'Status', type: 'boolean', values: [{ value: 1, label: "Updated" }, { value: 0, label: "Not Updated" }]},
                    ]}
                    onDelete={onDelete}
                    title={"Center"}
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'project_name') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key2={"company_details.company_name"} key3={"description"} />
                    }}
                    totalPaginationCount={centerState?.count}
                    onChangeBatch={onChangeBatch}
                    onUploadResults={onUploadResults}
                >
                    <p> </p>
                    <Input maxLength={100} label={"Center Name"} name={"center_name"} required={true} />
                    <Input maxLength={500} label={"Center Code "} name={"center_code"} required={true} />
                    <Input maxLength={30} label={"Latitude"} name={"latitude"} required={false} />
                    <Input maxLength={15} label={"Longitude"} name={"longitude"} required={false} />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}