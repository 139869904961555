import * as React from "react";
import { Input, ViewSave, makeQuery, makeSearchQuery } from "../../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../../services/types";
import { Box, Button, Divider, Grid, Stack, Typography, capitalize } from "@mui/material";
import { css as customCss } from '../../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Info from "../../regular/Info";
import HBox from "../../extra/HBox";
import { BorderBottom } from "@mui/icons-material";
import XForm from "../../../libs/inputs/XForm";
import { BarChart } from '@mui/x-charts/BarChart';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';


export default function Reports(props) {
    const [more, setMore] = React.useState({});
    const [mapper, setMapper] = React.useState({});
    const [modelProps, setModelProps] = React.useState([]);
    const [links, setLinks] = React.useState([]);
    const [getIds, setGetIds] = React.useState([]);
    const [whereIds, setWhereIds] = React.useState([]);

    let reportState = useSelector((state) => state.report?.data)
    let dashboardState = useSelector((state) => state.dashboard?.data)
    const [charts, setCharts] = React.useState([]);
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.REPORT.GET.ACTION, payload: 0, callback: (res) => {
                console.log("RESP", res)
                setCharts(res?.results)
            }
        })
        dispatch({ type: REQUESTS.DASHBOARD.GET.ACTION, payload: 0 })
        console.log("DATAS", reportState.results)
    }, [])


    const headerList = [
        { key: 'loan_unique_no', label: 'Loan Number', type: 'string', unique: true },
        { key: 'loan_unique_id', label: 'Loan Unique Id', type: 'string' },
        {
            key: 'is_active', label: 'Status', type: 'boolean',
            values: [{ value: 1, label: "Active" }, { value: 0, label: "Closed" }]
        },
        {
            key: 'is_updated', label: 'Updated', type: 'boolean',
            values: [{ value: 1, label: "Updated" }, { value: 0, label: "Not Updated" }]
        },
    ]

    const onSubmit = (e, data) => {
        if (data?.image && data?.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.LOAN.PATCH.ACTION : REQUESTS.LOAN.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.LOAN.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?filter_by=${query}` })
    }

    const onClickSort = (column) => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?sort_by=${column}` })
    }
    const onClickRefresh = () => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: 0 })
    }
    const onClickPagination = (number) => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?page=${number}` })
    }
    const onClickSearch = (value) => {
        let query = makeSearchQuery(headerList.map((head) => head.key), value)
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?search_by=${query}` })
    }
    const onChangeBatch = (uniqueColumn, batch, callback) => {
        //call api
        let data = { model: "loan", list: batch, unique_column: uniqueColumn }
        console.log("BATCH", batch)
        dispatch({
            type: REQUESTS.FILEUPLOAD.POST.ACTION, payload: data, callback: (data) => {
                callback(batch)
            }
        })

    }
    const onUploadResults = (results) => {
        console.log("UPLOADED", results.length)
        console.log("UPLOADED DATA", results)
    }

    const fetchLink = (e, operation) => {
        dispatch({
            type: REQUESTS.MODELPROPS.GET.ACTION,
            payload: `props/0/?model=${e.target.value}`,
            callback: (data) => {
                if (operation === "getIds") {
                    setGetIds(data.results)
                }
                if (operation === "whereIds") {
                    setWhereIds(data.results)
                }
                if (operation === "links") {
                    console.log("LINKS", data)
                    setLinks(data.results)
                }

            }
        })
    }
    const selectReportOf = (e) => {
        dispatch({
            type: REQUESTS.MODELPROPS.GET.ACTION,
            payload: `props/0/?model=${e.target.value}`,
            callback: (data) => {
                setModelProps(data.results)
            }
        })
        console.log(e.target.value)
    }
    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <XForm onSubmit={(e, data) => {
                    if (data?.props) {
                        data.props = JSON.stringify(data.props)
                    }
                    dispatch({ type: REQUESTS.REPORT.POST.ACTION, payload: data, callback: (resp) => { } })
                    console.log(data)
                }}>
                    <Box sx={{ ...customCss.List.Frame, p: 2 }}>
                        <Stack direction="row" spacing={2}>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Report Of <StorageOutlinedIcon sx={{ fontSize: 12 }} /></Typography>
                                <Input type={"select"} label="Add New Model"
                                    options={dashboardState?.results}
                                    text="name"
                                    onChange={(e) => { fetchLink(e, "getIds") }}
                                    name="model"
                                />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}> Column <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>

                                <Input type={"select"} label="column"
                                    options={getIds}
                                    text="name"
                                    name="select"
                                    style={{ BorderBottom: 0 }} />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Order By <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>
                                <Input type={"select"} label="Order By"
                                    options={getIds}
                                    text="name"
                                    onChange={(e) => { fetchLink(e) }}
                                    name="order_by"
                                />
                            </Box>
                        </Stack>
                         <Stack direction="row" spacing={2}>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Link <StorageOutlinedIcon sx={{ fontSize: 12 }} /></Typography>
                                <Input type={"select"} label="Add New Model"
                                    options={dashboardState?.results}
                                    text="name"
                                    onChange={(e) => { fetchLink(e, "whereIds") }}
                                    name="model"
                                />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}> Link ID <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>

                                <Input type={"select"} label="Add New Connector"
                                    options={whereIds}
                                    text="name"
                                    name="link_select"
                                    style={{ BorderBottom: 0 }} />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Conditions <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>
                                <Input type={"select"} label="Add New Model"
                                    options={whereIds}
                                    text="name"
                                    name="where"
                                />
                            </Box>
                        </Stack>
                         <Stack direction="row" spacing={2}>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Chart Of <StorageOutlinedIcon sx={{ fontSize: 12 }} /></Typography>
                                <Input type={"select"} label="Chart Of"
                                    options={dashboardState?.results}
                                    text="name"
                                    onChange={(e) => { fetchLink(e, "links") }}
                                    name="model"
                                />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}> Link ID <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>

                                <Input type={"select"} label="Add New Connector"
                                    options={links}
                                    text="name"
                                    name="link"
                                    style={{ BorderBottom: 0 }} />
                            </Box>
                            <Box item>
                                <Typography sx={{ fontSize: 12 }}>Link Model <InsertLinkOutlinedIcon sx={{ fontSize: 16 }} /></Typography>
                                <Input type={"select"} label="Add New Model"
                                    options={links}
                                    text="name"
                                    // onChange={(e) => { fetchLink(e,"links")}}
                                    name="model"
                                />
                            </Box>
                        </Stack>
                        <hr/>
                        <Stack direction="row" spacing={2} sx={{ pb: 2 }}>
                            <Input type={"select"} multiple={true} label="Add New Propeties"
                                options={modelProps}
                                text="name"
                                name="props"
                                style={{ BorderBottom: 0, height: 110 }}
                            />
                            <Input type={"select"} label="Operator"
                                options={links}
                                text="name"
                                name="link"
                                style={{ BorderBottom: 0 }} />
                        </Stack>

                        <Button type="submit" size="small">SUBMIT</Button>

                    </Box>
                </XForm>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={1}>

                    {charts && Object.keys(charts).map((key) => <Grid item xs={12} md={6}><Box sx={{ ...customCss.List.Frame, p: 2 }}>
                        <Typography>
                            {key?.replace("_", " ")}
                        </Typography>
                        <BarChart
                            series={[
                                { data: charts[key]?.map((value, i) => value.data.length > 0 ? value.data[0].dcount : 0) || [0], label: 'Not Updated', id: 'no' },
                                { data: charts[key]?.map((value, i) => value.data.length == 2 ? value.data[1].dcount : 0) || [0], label: 'Updated', id: 'yes' },
                            ]}
                            xAxis={[{ data: charts[key]?.map((data, i) => data.name) || [""], scaleType: 'band' }]}
                            height={250}
                        />
                    </Box> </Grid>)}
                </Grid>
            </Grid>
        </Grid>
    </>
}