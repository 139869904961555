import * as React from "react";
import { Input, ViewSave, makeQuery, makeSearchQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, Stack, Typography, capitalize } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Details from "./Details";
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function Loan(props) {
    const [more, setMore] = React.useState({});
    let loanState = useSelector((state) => state.loan?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: 0 })
    }, [])

    const headerList = [
        { key: 'loan_unique_no', label: 'Loan Number', type: 'string', unique: true },
        {
            key: 'is_active', label: 'Status', type: 'boolean',
            values: [{ value: 1, label: "Active" }, { value: 0, label: "Closed" }]
        },
        {
            key: 'is_updated', label: 'Updated', type: 'boolean',
            values: [{ value: 1, label: "Updated" }, { value: 0, label: "Not Updated" }]
        },
    ]

    const onSubmit = (e, data) => {
        if (data?.image && data?.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.LOAN.PATCH.ACTION : REQUESTS.LOAN.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.LOAN.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?filter_by=${query}` })
    }

    const onClickSort = (column) => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?sort_by=${column}` })
    }
    const onClickRefresh = () => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: 0 })
    }
    const onClickPagination = (number) => {
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?page=${number}` })
    }
    const onClickSearch = (value) => {
        let query = makeSearchQuery(headerList.map((head) => head.key), value)
        dispatch({ type: REQUESTS.LOAN.GET.ACTION, payload: `loan/0/?search_by=${query}` })
    }
    const onChangeBatch = (uniqueColumn, batch, callback) => {
        //call api
        let data = { model: "loan", list: batch, unique_column: uniqueColumn }
        console.log("BATCH", batch)
        dispatch({
            type: REQUESTS.FILEUPLOAD.POST.ACTION, payload: data, callback: (data) => {
                callback(batch)
            }
        })

    }
    const onUploadResults = (results) => {
        console.log("UPLOADED", results.length)
        console.log("UPLOADED DATA", results)
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
               
                <ViewSave
                    title={"Loan"} 
                    height="60vh"
                    headerList={headerList}
                    showHeader
                    showRefresh
                    showUpload
                    showPagination
                    showSort
                    dataList={loanState?.results}
                    onSubmit={onSubmit}
                    onClickFilter={onClickFilter}
                    onClickSort={onClickSort}
                    onClickRefresh={onClickRefresh}
                    onClickSearch={onClickSearch}
                    onClickPagination={onClickPagination}
                    totalPaginationCount={loanState?.count}
                    onChangeBatch={onChangeBatch}
                    onUploadResults={onUploadResults}
                    onDelete={onDelete}
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    renderCol={(data, key) => {
                        if (key === 'is_updated') return data[key] ? <Typography className="success" fontSize={10}>Updated</Typography> : <Typography className="error" fontSize={10}>Not Updated</Typography>
                        if (key === 'is_active') return data[key] ? <Typography className="success" fontSize={10}>Active</Typography> : <Typography className="error" fontSize={10}>Closed</Typography>
                        if ("loan_unique_no" == key) return <HBox data={data} key1={key}  key4={"comment"} />
                    }}
                >
                    <p> </p>
                    <Input maxLength={100} label={"Loan Number"} name={"loan_unique_no"} required={true} />
                    <Input maxLength={500} label={"Loan Unique Id"} name={"loan_unique_id"} required={true} />
                    <Input type="checkbox" label={"Active"} name={"is_active"} required={false} />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}