import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TablePagination from '@mui/material/TablePagination';
import {
  Box,
  Divider,
  Button,
  CircularProgress,
  Chip,
  Paper,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import { XForm, Input, nextedKey } from "./Libs";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Typography from "@mui/material/Typography";
import { Cancel, CheckBox } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { css as customCss } from '../content/Style'
import FilterListIcon from '@mui/icons-material/FilterList';
import ZFilter from "./ZFilter";
import DoneIcon from '@mui/icons-material/Done';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import ZModal from "./ZModal";
import ZFiles from "./ZFiles";
/**
 * @param {Boolean} showHeader - The boolean
 * @param {Array} headerList - The array
 * @param {String} title - The string
 * @param {Array} dataList - The Array
 * @param {Function} onSubmit - The function (e, data)
 */
export default function ViewSave({
  showHeader,
  headerList,
  title,
  dataList,
  children,
  onSubmit,
  onClickFilter,
  onClickRefresh,
  onClickSearch,
  onClickSort,
  renderRow,
  renderCol,
  renderSearch,
  renderPagination,
  frame,
  titleBar,
  header,
  cover,
  body,
  row,
  head,
  col,
  onEdit,
  onDelete,
  onMore,
  elementCss,
  onSearch,
  height,
  hideCols,
  hideEditBtn,
  hideDelBtn,
  hideInfo,
  css,
  icon,
  customIcon,
  showKeys,
  showSort,
  showRefresh,
  showDwnload,
  showUpload,
  showDetailColumns,
  showPagination,
  noRenderList,
  addNewIcon,
  moreIcon,
  editIcon,
  deleteIcon,
  onClickPagination,
  handleChangeRowsPerPage,
  totalPaginationCount,
  initData,
  noActionCol,
  onChangeBatch,
  onUploadResults,
}) {
  let ref = React.createRef();
  let refTable = React.createRef();
  let refReset = React.createRef();
  const [sortStatus, setSortStatus] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [message, setMessage] = React.useState("This is the message");
  const [search, setSearch] = React.useState("");
  const [showDetails, setshowDetails] = React.useState(-1);
  const [showUploadModal, setshowUploadModal] = React.useState(false);
  const basicStyle = {
    Frame: {
      p: 1,
      ...customCss?.List?.Frame,
    },
    Title: {
      width: "100%",
      p: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ...customCss?.List?.Title,
    },
    SearchBox: {
      width: "100%",
      p: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ...customCss?.List?.SearchBox,
    },
    Header: {
      width: "100%",
      p: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ...customCss?.List?.Header,
    },
    Body: {
      width: '100%',
      ...customCss?.List?.Body,
    },
    Row: {
      minWidth: 320,
      my: 1,
      mx: 1,
      ...customCss?.List?.Row,
    },
    Col: {
      my: 1,
      ...customCss?.List?.Col,
    },
    HCol: {
      my: 1,
      ...customCss?.List?.HCol,
    },
    HRow: {
      my: 1,
      borderBottom: '1px solid #edecec',
      ...customCss?.List?.HRow,
    },
    Cover: {
      width: '100%',
      maxHeight: height ? height : '70vh',
      overflowY: 'auto',
      ...customCss?.List?.Cover,

    },
    ColFirst: {
      ...customCss?.List?.ColFirst
    },
    ActionCol: {
      ...customCss?.List?.ActionCol
    }

  }
  showHeader = showHeader ? showHeader : false
  frame = frame ? frame : <Box sx={basicStyle.Frame}></Box>
  titleBar = titleBar ? titleBar : <Box sx={basicStyle.Title}></Box>
  header = header ? header : <Box sx={basicStyle.Header}></Box>
  body = body ? body : <Box sx={basicStyle.Body}></Box>
  cover = cover ? cover : <Box sx={basicStyle.Cover}></Box>
  row = row ? row : <Stack direction="row" spacing={1} sx={{ maxWidth: "100%" }}></Stack>
  head = head ? head : <Box alignContent={'center'}><Typography className="text-ellipse" sx={{ maxWidth: 150, ...customCss?.List?.Col, textTransform: 'capitalize' }}></Typography></Box>
  col = col ? col : <Box alignContent={'center'}><Typography className="text-ellipse" sx={{ maxWidth: 150, ...customCss?.List?.Col, textTransform: 'capitalize' }}></Typography></Box>
  const data = dataList?.length > 0 ? dataList[0] : {};
  const createHeader = (columns) => {
    let noactionhead = [...columns]
    if (columns.indexOf("Actions") == -1 && !noActionCol) columns.push("Actions")
    return [columns.map((key, i) => {
      if (typeof key == 'string') {
        return { key: String(key).toLowerCase(), label: key, type: (typeof data[key]), style: { textAlign: typeof data[key] == 'number' ? 'right' : 'left' } }
      }
      return key
    }), noactionhead.map((key, i) => {
      if (typeof key == 'string') {
        return { key: String(key).toLowerCase(), label: key, type: (typeof data[key]), style: { textAlign: typeof data[key] == 'number' ? 'right' : 'left' } }
      }
      return key
    })]
  }
  onClickSort = onClickSort ? onClickSort : (column) => { }
  onClickFilter = onClickFilter ? onClickFilter : (e, data) => { }
  onClickRefresh = onClickRefresh ? onClickRefresh : (e, data) => { }
  onClickSearch = onClickSearch ? onClickSearch : (value) => { }
  const headArr = ['index', icon].concat(Object.keys(data)).filter((value, index, array) => array.indexOf(value) === index)
  const [heading, forFilter] = createHeader(headerList ? headerList : headArr)

  onEdit = onEdit ? onEdit : (data, setShowModal, setLoading) => {
    setFormData(data);
    setShowModal(true);
  };
  onDelete = onDelete ? onDelete : (data, setLoading) => { };

  const ei = editIcon ? editIcon : <span style={customCss.Btn?.Edit}><Edit variant={"primary"}
    sx={{ fontSize: customCss.Btn?.Edit?.fontSize, color: customCss.Btn?.Edit?.color, cursor: "pointer" }} /></span>
  const di = deleteIcon ? deleteIcon : <span style={customCss?.Btn?.Delete}><Cancel variant={"error"} title={"Delete"}
    sx={{ fontSize: customCss.Btn?.Delete?.fontSize, color: customCss.Btn?.Delete?.color, cursor: "pointer" }}
  /></span>

  React.useEffect(() => {
    if (formData?.id) initData.id = formData?.id
    setFormData(initData)
  }, [initData])

  const onPageChange = (event, newPageNumber) => {
    setPage(newPageNumber);
    if (onClickPagination) { onClickPagination((newPageNumber)) }
  }

  handleChangeRowsPerPage = handleChangeRowsPerPage ? handleChangeRowsPerPage : () => { }
  onMore = onMore ? onMore : (data) => { }
  const mi = moreIcon ? moreIcon : <ExpandLess
    sx={{ fontSize: 24 }}
    onClick={() => {
      setshowDetails(-1);
    }}
  />
  noRenderList = noRenderList ? noRenderList : () => <div style={{ textAlign: 'center' }}>No data available</div>
  elementCss = elementCss ? elementCss : {
    sx: {
      width: "100%",
      overflowY: "auto",
      maxHeight: height ? height : "70vh",
    },
  };

  hideCols = hideCols ? hideCols : [];
  css = css ? css : { rowColor: "#e9f0f4" };
  icon = icon ? icon : "avatar";

  showDetailColumns = showDetailColumns ? showDetailColumns : Object.keys(data);

  const isIcons = (data) => {
    let eb = <Box sx={{ cursor: "pointer", p: .5 }} alignContent={'center'} onClick={() => { onEdit(data, setShowModal, setLoading, setFormData); }}>{ei}</Box>
    let db = <Box sx={{ cursor: "pointer", p: .5 }} alignContent={'center'} onClick={() => { onDelete(data, setLoading); }}>{di}</Box>
    let btns = [eb, db]
    if (hideEditBtn) btns = [di]
    if (hideDelBtn) btns = [ei]
    if (hideDelBtn && hideEditBtn) btns = []
    if (Array.isArray(moreIcon)) {
      btns = btns.concat(moreIcon.map((btn, i) => <Box sx={{ cursor: "pointer", p: .5 }} alignContent={'center'}
        onClick={() => { onMore(data, i) }}><span style={customCss.Btn?.Warn}>{btn}</span></Box>))
    } else if (moreIcon) {
      btns.push(<Box sx={{ cursor: "pointer", p: .5 }} alignContent={'center'}
        onClick={() => { onMore(data) }}>
        <span style={customCss.Btn?.Warn}>{moreIcon}</span>
      </Box>)
    }
    return btns
  }
  const renderValue = (value) => {
    if (typeof value === "string") return value
    if (typeof value === "number") return value
  }
  const iconOrValue = (data, key) => {
    if (icon == key) {
      return customIcon ? customIcon : <Avatar width={20} src={data[icon]} />
    } else {
      return renderValue(nextedKey(data, key))
    }
  }
  renderCol = renderCol ? renderCol : (data, key, style) => React.cloneElement(col,
    { className: "text-ellipse", sx: { ...basicStyle.Col, ...style } },
    <>{iconOrValue(data, key)}</>
  )
  renderRow = renderRow ? renderRow : (data, { index }) => {
    return React.cloneElement(row, { sx: basicStyle.Row, key: index }, <>
      {heading.map((heads, i) => {

        if (showKeys) {
          return <div>{`"${heads?.key}",`}</div>;
        }
        if (hideCols.indexOf(heads?.key) > -1) return;

        if (String(heads?.key).toLowerCase() == "index") {
          return React.cloneElement(head,
            { className: "text-ellipse", sx: basicStyle.ColFirst }, (index + 1))
        } else if (icon == heads?.key) {
          return React.cloneElement(col,
            { className: "text-ellipse", sx: basicStyle.ColFirst },
            iconOrValue(data, icon)
          )
        } else if ("actions" == String(heads?.key).toLowerCase()) {
          return React.cloneElement(col,
            { className: "text-ellipse", sx: basicStyle.ActionCol },
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{isIcons(data)}</Box>
          )
        } else {
          return renderCol(data, heads?.key, heads?.style)
        }
      })}
    </>)
  };
  onSearch = onSearch ? onSearch : (value) => { };
  function exportToExcel(html) {
    var location = 'data:application/vnd.ms-excel;base64,';
    var excelTemplate = '<html> ' +
      '<head> ' +
      '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
      '</head> ' +
      '<body> ' +
      html +
      '</body> ' +
      '</html>'
    window.location.href = location + window.btoa(excelTemplate);
  }
  renderSearch = renderSearch ? renderSearch : () => {
    return (
      <>
        <Box display="flex" alignItems={"center"} sx={basicStyle.SearchBox}>
          <Stack flex direction="row" alignItems={"center"} width={'100%'}>
            <Box sx={{ minWidth: 120, width: '60%', maxWidth: 200 }}>
              <Input
                type={"search"}
                style={{
                  marginBottom: 0,
                  width: 120,
                  height:24
                }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  onSearch(e.target.value);
                }}
                size="small"
              />
            </Box>
            <Button sx={{ fontSize: 12 }} size="small" onClick={() => { onClickSearch(search) }}>Search</Button>
            <Box sx={{ width: '100%' }}></Box>
            {showRefresh && <>
              <RefreshIcon sx={{ fontSize: 14 }} size="small" onClick={() => { onClickRefresh(true) }} />
              <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
            </>}
            {showUpload && <>
              <FileUploadOutlined sx={{ fontSize: 14 }} size="small" onClick={() => { setshowUploadModal(true) }} />

              <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
            </>}
            {showDwnload && <>
              <FileDownloadOutlined sx={{ fontSize: 14 }} onClick={() => {
                exportToExcel(refTable.current.innerHTML)
              }} />
              <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
            </>}
            <ZFilter columns={forFilter} onChange={onClickFilter} icon={<FilterListIcon sx={{ fontSize: 14 }} customCss={customCss} />} />
          </Stack>
        </Box>
      </>
    );
  };
  const createHeading = () => {
    let h = heading.map((head, index) => {
      if (head?.label == "Actions") {
        return React.cloneElement(col, { sx: { ...basicStyle.HCol, width: 1, textAlign: 'right' }, key: index },
          <Typography fontSize={14}>{head?.label}</Typography>)
      }
      return React.cloneElement(col, { sx: { ...basicStyle.HCol, width: 1 }, key: index },
        <Typography cursor='pointer' fontSize={14} onClick={() => {
          if (showSort) {
            let action = sortStatus[head?.key] ? head?.key : "-" + head?.key
            let remove = sortStatus[head?.key] ? delete sortStatus[head?.key] : sortStatus[head?.key] = "set";
            onClickSort(action)
          }
        }}>
          {head?.label} {showSort && (sortStatus[head?.key] ? <ArrowUpwardIcon fontSize="9" /> : <ArrowDownwardIcon fontSize="9" />)}
        </Typography>
      )
    })
    return React.cloneElement(row, { sx: { ...basicStyle.Row, ...basicStyle.HRow }, key: 0 }, h)
  }
  const createBody = () => {
    return React.cloneElement(body,
      { sx: basicStyle.Body },
      <>
        {dataList &&
          dataList.map((data, i) => {
            return renderRow(data, {
              index: i,
              setFormData: setFormData,
              setShowModal: setShowModal,
              setLoading: setLoading,
            });
          })
        }
        {(dataList && dataList.length == 0) && noRenderList()}
      </>
    )
  }
  const renderParent = (dataList, renderRow) => {
    return React.cloneElement(
      cover,
      { sx: basicStyle.Cover },
      <>
        {showHeader && createHeading()}
        {createBody()}
      </>
    );
  };

  renderPagination = renderPagination
    ? renderPagination
    : () => {
      return (
        <>
          {showPagination && <TablePagination sx={{ px: 1 }}
            rowsPerPageOptions={[4]}
            component="div"
            count={(totalPaginationCount || 0)}
            rowsPerPage={dataList?.length || 0}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </>
      );
    };

  React.useEffect(() => { }, [dataList]);
  const onSubmitIn = (e, data) => {
    if (onSubmit) {
      let r = onSubmit(e, data, setLoading, setMessage, (data1) => {
        setFormData(data1);
      });
      if (r) setShowModal(false)
    }
  };
  return (
    <>
      <ZModal maxWidth={'xl'} showModal={showUploadModal} onAction={() => { setshowUploadModal(false) }}>
        <ZFiles
          headerList={headerList}
          onChangeBatch={onChangeBatch}
          onResults={onUploadResults} />
      </ZModal>
      <Dialog
        open={showModal && children}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Box sx={basicStyle.Title}>
            <Typography variant="caption">{title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={customCss.Form}>
            <XForm data={formData} onSubmit={onSubmitIn}>
              {children}
              <input
                type={"reset"}
                ref={refReset}
                style={{ display: "none" }}
              />
              <input type={"submit"} ref={ref} style={{ display: "none" }} />
            </XForm>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 4 }}>
          <Button
            size="small"
            color="error"
            autoFocus
            onClick={() => {
              refReset.current.click();
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
          {loading && <CircularProgress sx={{ fontSize: 10 }} />}
          {!loading && (
            <Button
              size="small"
              color="success"
              onClick={() => {
                ref.current.click();
              }}
              autoFocus
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Box sx={basicStyle.Frame}>
        {title &&
          <>
            <Box sx={basicStyle.Title}>
              <Typography variant="caption"> {title}
              </Typography>
              {children && (
                <Typography variant="caption" sx={{ pt: 0.5 }} onClick={() => {
                  setFormData({});
                  setShowModal(true);
                }}>
                  {addNewIcon ? addNewIcon : <Chip size="small" label="Add New"
                    sx={{ fontSize: 8, color: customCss.Colors[0]?.color, backgroundColor: customCss.Colors[0]?.bg }} deleteIcon={<DoneIcon />} />}
                </Typography>
              )}
            </Box>
          </>}

        {renderSearch()}

        {!hideInfo && <Box display="flex" alignItems={"center"} sx={basicStyle.SearchBox}>
          <Box sx={{ textAlign: 'right', width: '100%', fontSize: 12 }}>Showing Rows {dataList ? dataList?.length : 0} {totalPaginationCount ? `/ ${totalPaginationCount}` : null}</Box>
        </Box>}
        <Box display="flex" sx={basicStyle.Body} ref={refTable}>
          {renderParent(dataList, renderRow)}
        </Box>
        {renderPagination()}
      </Box>
    </>
  );
}
