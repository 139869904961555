import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './services/store';
import { createTheme } from '@mui/material/styles';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        {/* <Router> */}
        <HashRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </HashRouter>
        {/* </Router> */}
    </React.StrictMode>
);


//service worker code
if (!('serviceWorker' in navigator)) {
    throw new Error('No Service Worker support!')
  }
  if (!('PushManager' in window)) {
    throw new Error('No Push API Support!')
  }
  navigator.serviceWorker.register('./service.js');