import SpaceDashboardSharpIcon from "@mui/icons-material/SpaceDashboardOutlined";
import LineStyleSharpIcon from "@mui/icons-material/LineStyleSharp";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import Group from '../pages/adminstration/group'
import UserPermission from '../pages/adminstration/user_permission'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import VideocamIcon from '@mui/icons-material/VideocamOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';

import Dashboard from "../pages/dashboard/Home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Users from "../pages/users/Users";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Profile from "../pages/profile/Profile";
import Team from "../pages/users/Team";

import Loan from "../pages/loan/Loan";
import Branch from "../pages/branch/Branch";
import Center from "../pages/center/Center";
import Customer from "../pages/customer/Customer";
import Agent from "../pages/agent/Agent";


import IP from "../pages/auth/index/IndexPage";
import ContactUs from '../pages/auth/ContactUs'
import FileManager from "../pages/csv/FileManager";
import AgentReport from "../pages/reports/AgentReport";
import BranchReport from "../pages/reports/BranchReport";
import Reports from "../pages/settings/reports/Reports";

export const HomePage = Dashboard; //its shows as loagout page
export const IndexPage = Login; //its shows as loagout page

export const anonyMenuItems = [
  {
    name: "Index",
    exact: true,
    to: "/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login />,
    // noMenu: true,
  },
  {
    name: "Login",
    exact: true,
    to: "/login/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login hasForgot={"true"} />,
    // noMenu: true,
  },

  {
    name: "Register",
    exact: true,
    to: "/register/",
    icon: <PersonAddAlt1Icon />,
    component: <Register />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/reset/",
    icon: <PersonAddAlt1Icon />,
    component: <ForgotPassword title={"Reset Password"} submitLabel={"Reset"} />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Reset Password"} submitLabel={"Reset"} />,
    noMenu: true
  },
  {
    name: "Contact Us",
    exact: true,
    to: "/contact_us/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ContactUs title={"Contact Us"} phone={"9656291915"} email="sujithvs.think@gmail.com" address={"xyz"} />,
    noMenu: true
  }
];

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard/",
    icon: <SpaceDashboardSharpIcon sx={{ strokeWidth: 4 }} />,
    component: <Dashboard />,
    permission:"*",
  },
  {
    name: "Change Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Change Password"} submitLabel={"Change"} />,
    noMenu: true,
    permission:"*",
  },
  {
    name: "Group",
    exact: true,
    to: "/group/",
    icon: <GroupsIcon />,
    component: <Group />,
    permission:"group",
  },
  {
    name: "User Permission",
    exact: true,
    to: "/user_permission/",
    icon: <AdminPanelSettingsIcon />,
    component: <UserPermission />,
    permission:"permission",
  },
  {
    name: "Users",
    exact: true,
    to: "/users/",
    icon: <PeopleOutlined />,
    component: <Users />,
    permission:"user",
  },
  {
    name: "Team",
    exact: true,
    to: "/team/",
    icon: <PeopleOutlined />,
    component: <Team />,
    noMenu: true,
    permission:"permission",
  },
  {
    name: "Profile",
    exact: true,
    to: "/profile/",
    icon: <AccountBalanceIcon />,
    component: <Profile title={"Sujith VS"} />,
    noMenu: true
  },



  {
    name: "Loans",
    exact: true,
    to: "/loan/",
    icon: <MonetizationOnOutlinedIcon />,
    component: <Loan />,
    permission:"loan",
  },
  {
    name: "Branch",
    exact: true,
    to: "/branch/",
    icon: <AccountBalanceIcon />,
    component: <Branch />,
    permission:"permission",
  },

  {
    name: "Center",
    exact: true,
    to: "/center/",
    icon: <HubOutlinedIcon />,
    component: <Center />,
    permission:"permission",
  },
  {
    name: "Customer",
    exact: true,
    to: "/customer/",
    icon: <HailOutlinedIcon />,
    component: <Customer />,
    permission:"permission",
  },
  {
    name: "Agent",
    exact: true,
    to: "/agent/",
    icon: <SupportAgentOutlinedIcon />,
    component: <Agent />,
    permission:"permission",
  },
  {
    name: "Reports",
    exact: true,
    to: "/reports/",
    icon: <BarChartOutlined />,
    component: <Reports />,
  },
  {
    name: "File",
    exact: true,
    to: "/file/",
    icon: <ArticleOutlinedIcon />,
    component: <FileManager />,
    noMenu: true
  },

  {
    name: "Agent Reports",
    exact: true,
    to: "/agentreport/",
    icon: <ArticleOutlinedIcon />,
    component: <AgentReport />,
    permission:"reports",
    
  },
  {
    name: "Branch Report",
    exact: true,
    to: "/branchReport/",
    icon: <ArticleOutlinedIcon />,
    component: <BranchReport />,
    permission:"reports",
    
  },

];
