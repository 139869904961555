import * as React from "react";
import { Input, ViewSave, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, Stack, capitalize } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Details from "./Details";
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function Customer(props) {
    const [more, setMore] = React.useState({});
    let customerState = useSelector((state) => state.customer?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.CUSTOMER.PATCH.ACTION : REQUESTS.CUSTOMER.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.CUSTOMER.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: `customer/0/?filter_by=${query}` })
    }
    const onClickSort = (column) => {
        dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: `customer/0/?sort_by=${column}` })
    }
    const onClickRefresh = () => {
        dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: 0 })
    }
    const onClickPagination = (number) => {
        dispatch({ type: REQUESTS.CUSTOMER.GET.ACTION, payload: `customer/0/?page=${number}` })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave
                    showHeader
                    showSort
                    showPagination
                    showRefresh
                    dataList={customerState?.results}
                    onSubmit={onSubmit}
                    onClickFilter={onClickFilter}
                    onClickSort={onClickSort}
                    onClickRefresh={onClickRefresh}
                    headerList={[{ key: "customer_name", label: "Customer Name", type: "string" }]}
                    hideMore={["id", "created_at", "modified_date"]}
                    onDelete={onDelete}
                    title={"Customers"}
                    icon="image"
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'customer_name') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key2={"customer_number"} key3={"customer_email"} key4={"customer_phone_no"} />
                    }}
                    onClickPagination={onClickPagination}
                    totalPaginationCount={customerState?.count}
                >
                    <p> </p>
                    <Input maxLength={100} label={"Project Name"} name={"project_name"} required={true} />
                    <Input maxLength={500} label={"Description "} name={"description"} required={false} />
                    <Input maxLength={30} label={"Country"} name={"country"} required={false} />
                    <Input maxLength={15} label={"Location "} name={"location"} required={false} />
                    <Input maxLength={30} label={"Duration"} name={"duration"} required={true} />
                    <Stack direction="row" spacing={1}>
                        <Input type="checkbox" label={"Verify"} name={"verify"} required={false} />
                        <Input type="checkbox" label={"Active"} name={"active"} required={false} />
                    </Stack>
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}